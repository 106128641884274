import { Canvas, useFrame, extend } from "@react-three/fiber"
import { Float, Lightformer, Text, Html, ContactShadows, Environment, MeshTransmissionMaterial, Image, useGLTF } from "@react-three/drei"
import { Bloom, EffectComposer, N8AO, TiltShift2 } from "@react-three/postprocessing"
import { suspend } from "suspend-react"
import { easing, geometry } from "maath"

const suse = import("./exil.woff")
const background = import("./scene.png")


extend({ RoundedPlaneGeometry: geometry.RoundedPlaneGeometry })

export const App = () => (
  <>
    <Canvas eventSource={document.getElementById("root")} eventPrefix="client" shadows camera={{ position: [0, 0, 20], fov: 50 }}>
      <color attach="background" args={["#EBFFFF"]} />
      <spotLight position={[20, 20, 10]} penumbra={1} castShadow angle={0.2} />
      <Identity position={[0, 0, -6]} scale={[.5, .5, .5]} />
      <Image url={suspend(background).default} position={[0, -24, -28]} scale={[120, 120]}>
      </Image>
      <Float floatIntensity={2}>
        
      </Float>
      
      <Environment preset="city">
        <Lightformer intensity={8} position={[10, 5, 0]} scale={[10, 50, 1]} onUpdate={(self) => self.lookAt(0, 0, 0)} />
      </Environment>
      <EffectComposer disableNormalPass>
        <N8AO aoRadius={1} intensity={2} />
        <Bloom mipmapBlur luminanceThreshold={0.8} intensity={2} levels={8} />
        <TiltShift2 blur={0.2} />
      </EffectComposer>
      <Rig />
      
    </Canvas>
    
  </>
)

function Rig() {
  useFrame((state, delta) => {
    easing.damp3(
      state.camera.position,
      [Math.sin(-state.pointer.x) * 5, state.pointer.y * 3.5, 15 + Math.cos(state.pointer.x) * 10],
      0.2,
      delta,
    )
    state.camera.lookAt(0, 0, 0)
  })
}

const Drop = (props) => (
  <mesh>
    <sphereGeometry args={[1, 64, 64]} />
    <MeshTransmissionMaterial backside backsideThickness={5} thickness={2} />
  </mesh>
)

const Torus = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusGeometry args={[4, 1.2, 128, 64]} />
    <MeshTransmissionMaterial backside backsideThickness={5} thickness={2} />
  </mesh>
)

const Knot = (props) => (
  <mesh receiveShadow castShadow {...props}>
    <torusKnotGeometry args={[3, 1, 256, 32]} />
    <MeshTransmissionMaterial backside backsideThickness={5} thickness={2} />
  </mesh>
)

function Identity(props) {
  return (
    <>
    <Text fontSize={3} letterSpacing={-0.025} font={suspend(suse).default} color="black" {...props}>
      EN MODE
    </Text>

    

    </>
  )
}
